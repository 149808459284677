import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ArticleCard from '../components/articles/BlogArticleCard'
import { getRandomBackground } from '../utils'
import _ from 'lodash'
import PageHeader from '../components/general/PageHeader'

const AuthorProfileTemplate = (props) => {
  const { author, helmet } = props
  const { articles } = author

  // sorts articles and filter not published articles (or those whose publishDate hasn't arrived)
  // ToDo: this should be done server-side, but this is a workaround until we do
  // the problem with doing it server-side is to figure a way to filter articles within authors
  // as there doesn't seem to be an easy way to do so for nested relationships
  const sortedArticles = _.filter(
    _.orderBy(articles, ['publishDate'], ['desc']),
    (article) => {
      return (
        new Date(article.publishDate) < new Date() &&
        article.status === 'Published'
      )
    }
  )

  const hasBio = author.bio && author.bio.length > 10

  return (
    <section>
      {helmet || ''}

      <PageHeader className="hassan_3" backgroundImage={getRandomBackground()}>
        {author.name}
      </PageHeader>

      <div className="columns is-gapless">
        <div className="container">
          <div className="column is-12">
            <div className="column is-12">
              {hasBio && <div className="content">{author.bio}</div>}
              <hr />
              {sortedArticles && sortedArticles.length && (
                <h1 className="title">الكتابات</h1>
              )}
            </div>
            <div className="columns is-gagless is-multiline">
              {sortedArticles.map((article) => (
                <ArticleCard
                  key={article.id}
                  showFeaturedImage
                  author={author}
                  post={article}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

AuthorProfileTemplate.propTypes = {
  helmet: PropTypes.object,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    screenName: PropTypes.string.isRequired,
    bio: PropTypes.string,
    link: PropTypes.string,
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        slug: PropTypes.string,
        body: PropTypes.string,
        excerpt: PropTypes.string,
      })
    ),
  }),
}

const AuthorPage = ({ data }) => {
  const { strapiAuthor: author } = data

  return (
    <Layout>
      <AuthorProfileTemplate
        author={author}
        helmet={
          <Helmet titleTemplate="%s | مركز الدراسات السودانية">
            <title>{`${author.name}`}</title>
            <meta property="og:type" content="author" />
            <meta name="description" content={`${author.bio}`} />
          </Helmet>
        }
      />
    </Layout>
  )
}

AuthorPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    strapiAuthor: PropTypes.shape({}),
  }),
}

export default AuthorPage

export const pageQuery = graphql`
  query AuthorByID($id: String!) {
    strapiAuthor(id: { eq: $id }) {
      id
      strapiId
      name
      screenName
      bio
      articles {
        excerpt
        id
        publishDate
        slug
        title
        body
        status
        image {
          url
        }
      }
      updated_at
      created_at
    }
  }
`
