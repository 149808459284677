import React from 'react'
import ErrorBoundary from '../ErrorBoundary'
import PropTypes from 'prop-types'

const PostImage = ({ post: { image, title, author = {} } }) => {
  let finalImage = null
  if (image && image.url) {
    finalImage = image.url
    if (image.formats && image.formats.small) {
      finalImage = image.formats.small.url
    }
  } else if (author.image && author.image.url) {
    finalImage = author.image.url
    if (author.image.formats && author.image.formats.small) {
      finalImage = author.image.formats.small.url
    }
  } else return null
  return (
    <ErrorBoundary>
      <img src={finalImage} alt={`${title} | ${author && author.name}`} />
    </ErrorBoundary>
  )
}

PostImage.propTypes = {
  post: PropTypes.shape({
    image: PropTypes.shape({
      url: PropTypes.string,
      formats: PropTypes.shape({
        small: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    title: PropTypes.string,
    author: PropTypes.shape({}),
  }),
}

export default PostImage
