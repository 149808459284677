import React from 'react'
import PropTypes from 'prop-types'

const ReadMoreLink = ({ link, children }) => {
  return (
    <div className="content has-text-left">
      <a className="button is-small is-outlined has-text-grey" href={`${link}`}>
        {children || 'اقرأ البقية'}
      </a>
    </div>
  )
}

ReadMoreLink.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.array,
}

export default ReadMoreLink
