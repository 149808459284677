import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import ReadMoreLink from '../general/ReadMoreLink'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import PostImage from './PostImage'

const EXCERPT_MAX_LENGTH = 480

const getExcerpt = (post, length = EXCERPT_MAX_LENGTH) => {
  const { body = '', excerpt } = post

  if (excerpt) return `${excerpt.substring(0, length)}...`
  return `${body.substring(0, length)}...`
}

const BlogArticleCard = ({
  post,
  author,
  excerptLength,
  hideExcerpt,
  className,
  titleStyle,
  showFeaturedImage,
  showReadMoreLink = false,
}) => {
  const excerpt = getExcerpt(post, excerptLength)
  const classes = className || 'column is-4'
  const featuredClasses = post.isFeatured ? 'is-featured' : ''
  const titleClassNames = titleStyle || 'content is-size-6 has-text-link'

  const track = (action) => () => {
    trackCustomEvent({
      category: 'ArticleCard',
      action,
    })
  }

  const hasImage = post.image && post.image.url
  const hasAuthorImage =
    post.author && post.author.image && post.author.image.url

  const showImage = (hasImage || hasAuthorImage) && showFeaturedImage

  return (
    <div className={`column margin-bottom-20  ${classes} ${featuredClasses} `}>
      <div className="card">
        <div className="card-image">
          {showImage && (
            <figure className="image is-4by3">
              <div className="column is-5 has-text-centered">
                <a onClick={track('titleClick')} href={`/${post.slug}`}>
                  <PostImage post={post} />
                </a>
              </div>
            </figure>
          )}
          {!showImage && (
            <div className=" has-background-grey-lighter is-4by3 image "></div>
          )}
        </div>
        <div className="card-content">
          <div className="content">
            <div className="title">
              <Link
                onClick={track('titleClick')}
                className={titleClassNames}
                to={`/${post.slug}`}
              >
                {post.title}
              </Link>
            </div>
            {!hideExcerpt && (
              <div>
                <div>
                  <p className="excerpt has-text-justified is-size-7">
                    {excerpt}
                    {showReadMoreLink && (
                      <div onClick={track('readmoreClick')}>
                        <ReadMoreLink link={post.slug} />
                      </div>
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>
          <footer>
            <div className="media">
              <div className="media-right">
                <figure className="image is-48x48 ">
                  <img
                    className="is-rounded"
                    src="https://bulma.io/images/placeholders/96x96.png"
                    alt=""
                  />
                </figure>
              </div>
              <div className="media-content">
                <p className="title is-6 has-text-right media-content">
                  {author && (
                    <Link
                      onClick={track('authorClick')}
                      className="has-text-link"
                      to={`/authors/${author.screenName}`}
                    >
                      {author.name}
                    </Link>
                  )}
                </p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

BlogArticleCard.propTypes = {
  post: PropTypes.shape({
    isFeatured: PropTypes.bool,
    slug: PropTypes.string,
    title: PropTypes.string.isRequired,
    author: PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    screenName: PropTypes.string.isRequired,
  }).isRequired,
  excerptLength: PropTypes.number,
  titleStyle: PropTypes.string,
  showFeaturedImage: PropTypes.bool,
  showReadMoreLink: PropTypes.bool,
  hideExcerpt: PropTypes.bool,
  className: PropTypes.string,
}

export default BlogArticleCard
